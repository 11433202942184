import React from 'react';

import { CSSTransition } from 'react-transition-group';
import { ArrowRight, ExclamationMark } from 'tabler-icons-react';

import { MaxLengthIndicator } from './MaxLengthIndicator';
import { InputProps } from './Commons';
import { useWordCount } from 'domain/hooks/wordCount';
import { readOnlyFromProps } from 'pages/shared/rjsf/utils';
import './Input.scss';

export const Input = React.forwardRef((props: InputProps, ref?: any) => {
  const { className, ...otherProps } = props;

  return (
    <input className={`input ${className || ''}`} ref={ref} {...otherProps} />
  );
});

export const InputWithErrors = React.forwardRef(
  (props: InputProps & { error?: string }, ref: any) => {
    const { error, className, ...otherProps } = props;

    return (
      <div className={`input-w-error ${error ? 'has-error' : ''}`}>
        <Input {...otherProps} ref={ref} />
        <CSSTransition
          timeout={200}
          unmountOnExit
          in={!!error}
          classNames='input-error-transition'
        >
          <span className='error'>
            <ExclamationMark className='error-icon' />
            {error}
          </span>
        </CSSTransition>
      </div>
    );
  }
);

export const InputWithButton = React.forwardRef(
  (
    props: InputProps & {
      onButtonClick(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    },
    ref: any
  ) => {
    const { className, onButtonClick, ...otherProps } = props;

    return (
      <div className='input-w-button'>
        <Input {...otherProps} className='input-w-button-input' ref={ref} />
        <button onClick={onButtonClick} className='input-w-button-button'>
          <ArrowRight className='input-w-button-icon' />
        </button>
      </div>
    );
  }
);

export const LimitedInput = React.forwardRef(
  (
    {
      className = '',
      maxLength = 150,
      onChange,
      ...props
    }: InputProps & { maxLength?: number },
    ref: any
  ) => {

    const valueLength = useWordCount(props.value);

    const intChange: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
      
      if (!maxLength || maxLength < 0 || valueLength <= maxLength) {
        onChange?.(evt);
      }
    };
  
    const disabled = readOnlyFromProps(props);


    if (disabled) {
      return (<div className={`limited-input-wrapper disabled break-words max-w-[100%] ${className}`}>
          <div className='value break-words bg-[var(--base-bg-dark)] p-2 rounded-6'>
            {props.value}
          </div>
          <MaxLengthIndicator currentLength={valueLength} maxLength={maxLength} />
        </div>
      );
    }

    return (
      <div className={`limited-input-wrapper ${className}`}>
        <Input {...props} onChange={intChange} ref={ref} />
        <MaxLengthIndicator currentLength={valueLength} maxLength={maxLength} />
      </div>
    );
  }
);

export const Checkbox = React.forwardRef(
  (props: Omit<InputProps, 'type'>, ref: any) => {
    const { className, ...otherProps } = props;

    return (
      <input
        type='checkbox'
        className={`checkbox ${className || ''}`}
        ref={ref}
        {...otherProps}
      />
    );
  }
);
