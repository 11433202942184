export const Api = {
  baseUrl: process.env.REACT_APP_API_URL || window.location.origin + '/api/v1',
  auth: {
    login: '/auth/signin',
    renew: '/auth/refresh',
    register: '/register',
    account: '/account',
    me: '/me',
    apiKey: '/users/api',
  },
  workflows: {
    findAll: '/workflows',
    config: '/workflows/config',
    definition: (processId: string) => `/workflows/process/${processId}/definition`,
    diagramImage: (process: string) => `/workflows/process/${process}/diagram`,
  },
  executions: {
    findAll: '/workflows/executions',
    create: '/workflows/executions',
    reopen: (executionId: string) =>
      `/workflows/executions/${executionId}/reopen`,
    requestReopen: (executionId: string) =>
      `/workflows/executions/${executionId}/requestReopen`,
    exists: "/workflows/exists",
  },
  tasks: {
    findAll: '/workflows/executions/tasks',
    findAllExecution: '/tasks',
    data: (id: string) => `/workflows/executions/tasks/${id}/data`,
    history: (id: string) => `/workflows/process/${id}/history`,
    jsonSchema: (id: string) => `/workflows/executions/tasks/${id}/json-schema`,
  },
  qats: {
    findAll: '/eqc/qats',
  },
  qars: {
    findAll: '/eqc/qars',
    findVersions: '/eqc/qars/versions',
    pullRequest: (qarId: number) => `/schemas/qa/qar/${qarId}/pull-request`,
    getQarPDF: (qarId: number | string, showAll: boolean) =>
      `/schemas/qa/full?qarId=${qarId}&showAll=${showAll}`,
  },
  families: {
    findAll: '/eqc/qat-families',
  },
  catalogEntries: {
    findAll: '/eqc/catalog-entries',
  },
  eqcDashboard: {
    listAll: '/eqc/integrations/dashboard',
  },
  messageThread: {
    findByExecutionId: (executionId: string) =>
      `/chat/executions/${executionId}`,
    persist: (executionId: string) => `/chat/executions/${executionId}/persist`,
    create: '/chat',
    delete: '/chat',
  },
  schemas: {
    findAll: '/admin/task-schemas',
  },
  references: {
    find: '/references',
  },
  admin: {
    roles: '/admin/users/roles',
    workflow_roles: '/admin/users/workflow-roles',
    workflows: {
      config: '/admin/workflows/config',
    },
    acls: {
      roles: '/admin/acls/roles',
      groups: '/admin/acls/groups',
    },
    users: '/admin/users',
    pullRequests: '/admin/pull-request-log',
    userRequests: '/admin/user-requests',
  },
  dashboard: {
    calendar: {
      findAll: `/dashboard/calendar`,
      update: '/dashboard/calendar/updateEvent',
    },
  },
  uploads: {
    images: '/uploads/images',
  },
  useCaseAttachments: {
    upload: (qarId: number) => `/eqc/qars/${qarId}/attachments`,
    delete: (qarId: number, id: string) =>
      `/eqc/qars/${qarId}/attachments/${id}`,
    list: (qarId: number, ids: string[]) =>
      `/eqc/qars/attachments?${ids
        .map((id) => `filters=[EQ][id]${id}`)
        .join('&')}`,
  },
} as const;

export const Misc = {
  ReviewColors: [
    {
      color: '#d73027',
      text: 'Not consistent with CADS',
    },
    {
      color: '#f46d43',
      text: 'Partially consistent with CADS',
    },
    {
      color: '#1a9850',
      text: 'Fully consistent with CADS',
    },
  ],
} as const;

export const Config = {
  MAX_WORDS_TEXTAREA: -1,
};
