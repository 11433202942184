import React, { useMemo } from 'react';

import './MaxLengthIndicator.scss';

interface MaxLengthIndicatorProps {
  className?: string;
  maxLength?: number;
  currentLength: number;
}
export const MaxLengthIndicator = (props: MaxLengthIndicatorProps) => {

  const limitReached = useMemo(() => {
    if (!props?.maxLength) {
      return false;
    }
    if (props.maxLength < 0) {
      return false;
    }
    return props.currentLength >= props.maxLength;
  }, [props.currentLength, props.maxLength]);

  const maxLengthText = useMemo(() => {
    if (props.maxLength === undefined) {
      return '';
    }

    if (props.maxLength < 0) {
      return '';
    }
    return props.maxLength.toString();
  }, [props.maxLength]);

  return props.maxLength !== undefined ? (
    <span
      className={`max-length-indicator ${props.className ?? ''} ${
        limitReached ? 'limit-reached' : ''
      }`}
    >
      {props.currentLength}/{maxLengthText}
    </span>
  ) : null;
};
